<template>
  <div class="page-gray">
    <van-tabs
        v-model="active"
        sticky
        swipeable
        :line-width="15"
        :line-height="4">
      <van-tab title="待审核" name="0">
        <to-audit-list v-if="active=='0'" />
      </van-tab>
      <van-tab title="审核记录"  name="1">
        <approved-list v-if="active=='1'" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import ApprovedList from './listComponent/approvedList.vue'
import ToAuditList from './listComponent/toAuditList.vue'

export default {
  name: "Audit",
  components: {
    ApprovedList,
    ToAuditList
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.back, false)
    }
  },
  // 2：需要在退出页面的时候销毁这个监听事件
  destroyed() {
    window.removeEventListener('popstate', this.back, false)
  },
  data() {
    return {
      active: '0'
    }
  },
  methods:{
    back() {
      this.$router.replace('/mine')
    },
  }
}

</script>
<style lang="less" scoped>

</style>
