<template>
  <div class="goodsList">
    <van-row :gutter="10">
      <van-col :span="12" v-for="(item,index) in products" :key="index">
        <div class="item" @click="$router.push({path:'/auditDetail',query:{id:item.id}})">
          <div class="imgBox">
            <img :src="item.mainPic" alt="">
            <div>待审核</div>
          </div>

          <div class="goodsInfo">
            <div class="goodsName van-multi-ellipsis--l2">
              <span class="ys" v-show="item.libckflag ===0">预售</span>
              <span class="xh" v-show="item.libckflag ===1">现货</span>
              {{item.name}}
            </div>

            <div class="tagList">
              <div class="tag van-hairline--top" v-show="item.deliveryMode ==='2'">配送到校</div>
              <div class="tag" v-show="item.libckflag ===0">预售完生产</div>
              <div class="tag van-hairline--top" v-show="item.deliveryMode ==='1'">快递到家</div>
              <div class="tag van-hairline--top" v-show="item.storeId >0 ">门店购买</div>
            </div>

            <div class="handle">
              <div class="price">
                <span>¥</span>
                <span>{{ item.price }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-col>
    </van-row>


  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "toAuditList",
  data() {
    return {
      //待审核的商品
      products: [],
    }
  },
  mounted(){
    this.queryToBeReviewedProduct();
  },
  activated() {
    this.queryToBeReviewedProduct();
  },
  created() {
  },
  methods: {
    queryToBeReviewedProduct() {
      this.$http.get(`/product/h5_product/queryToBeReviewedProduct/${Vue.prototype.deptId}`).then(res => {
        this.products = res.data;
      });
    }
  }
}

</script>
<style lang="less" scoped>
.goodsList {
  padding: 10px;
}

.item {
  margin-bottom: 10px;
  background: #FEFEFE;
  border-radius: 8px;

  .imgBox {
    position: relative;
    height: 144px;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
    }

    div {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      height: 27px;
      background: #FF941A;
      text-align: center;
      color: #fff;

      font-size: 13px;
      font-weight: 500;
      color: #FEFEFE;

      line-height: 27px;
    }
  }

  .goodsInfo {
    padding: 3px 11px 0 11px;

    .goodsName {
      font-size: 12px;
      color: #333333;
      line-height: 17px;

      .ys {
        width: 32px;
        background: linear-gradient(264deg, #f58130, #ef2514);
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #F4F4F4;
        line-height: 12px;
        text-align: center;
        padding: 2px 0;
        display: inline-block;
        margin-right: 3px;

      }

      .xh {
        width: 32px;
        background: linear-gradient(264deg, #4db3ee, #00A0E9);
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #F4F4F4;
        line-height: 12px;
        text-align: center;
        padding: 2px 0;
        display: inline-block;
        margin-right: 3px;

      }
    }


    .tagList {
      padding: 5px 0 15px 0;

      .tag {
        border: 1px solid #FF0000;
        border-radius: 2px;
        padding: 3px 7px;
        font-size: 10px;
        font-weight: 400;
        color: #FF0000;
        line-height: 10px;
        display: inline-block;
        margin-right: 11px;

        &.plain {
          padding-left: 0;
          padding-right: 0;
          color: #666;
          border: none;
        }
      }
    }

    .handle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 27px;
      padding-bottom: 16px;

      .price {
        color: #D53A26;
        font-weight: 500;

        span:first-child {
          font-size: 12px;
          margin-right: 3px;
          font-weight: bold;
        }

        span:last-child {
          font-size: 19px;
          font-weight: bold;
        }
      }

    }
  }
}
</style>
