<template>
  <div>
    <div class="filter">
      <van-row :gutter="10">
        <van-col :span="12">
          <div class="input box">
            <van-icon name="search" :size="px2rem(14)" color="#666"></van-icon>
            <input type="text" v-model="schoolName" placeholder="请输入学校名称">
          </div>
        </van-col>
        <van-col :span="12">
          <div class="time box handle" @click="startTimePopup = true">
            <div class="value" v-if="startTime"> {{ startTime }}</div>
            <i/>
            <div class="value" v-if="endTime"> {{ endTime }}</div>
            <img src="@/assets/images/integral/arrow.png" alt="">
          </div>
        </van-col>

        <van-col :span="12">
          <div class="input box">
            <van-icon name="search" :size="px2rem(14)" color="#666"></van-icon>
            <input type="text" v-model="auditUser" placeholder="请输入审核人">
          </div>
        </van-col>

        <van-col :span="6">
          <div class="select box handle" @click="resultPicker = true">
            <div class="value" v-if="result"> {{ result }}</div>
            <div class="placeholder" v-else>审核结果</div>
            <img src="@/assets/images/integral/arrow.png" alt="">
          </div>
        </van-col>

        <van-col :span="6">
          <van-button type="primary" @click="searchChange" color="#D61619" round block>筛选</van-button>
        </van-col>
      </van-row>
    </div>
      <van-list
          v-model="tableLoading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
<!--        <van-cell v-for="item in list" :key="item" :title="item" />-->
        <div class="item"  v-for="item in tableData" :key="item.id">
          <div class="goodsInfo">
            <img :src="item.image" alt="">
<!--            <img src="@/assets/images/02.jpg" alt="">-->

            <div class="right">
              <div class="name">{{item.productName}}</div>
              <div class="price">
                <span>¥</span>
                <span>{{item.price}}</span>
              </div>
              <div class="tagList">
                <span class="tag" v-show="item.displayMode =='2'">配送到校</span>
                <span class="tag" v-show="item.displayMode =='1'">快递到家</span>
<!--                <div class="tag">限时预定</div>-->
              </div>
            </div>
          </div>

          <div class="timeGroup van-hairline--bottom">
            <div class="time">
              <div class="key">开售时间:</div>
              <div class="value">{{item.starttime}}</div>
<!--              <div class="value">2022-05-23 13:51:24</div>-->
            </div>
            <div class="time">
              <div class="key">截止时间:</div>
              <div class="value">{{item.lmtime}}</div>
<!--              <div class="value">2022-05-30 13:51:24</div>-->
            </div>
          </div>


          <div class="info">
            <div class="line">
              <div class="key">操作时间:</div>
              <div class="value">{{item.createTime}}</div>
            </div>
<!--            <div class="line">-->
<!--              <div class="key">审核时间:</div>-->
<!--              <div class="value">{{item.updateTime}}</div>-->
<!--            </div>-->
            <div class="line">
              <div class="key">操作人:</div>
              <div class="value">{{item.createBy}}</div>
            </div>
<!--            <div class="line">-->
<!--              <div class="key">操作人:</div>-->
<!--              <div class="value">{{item.updateBy}}</div>-->
<!--            </div>-->
            <div class="line">
              <div class="key">操作阶段:</div>
              <div class="value">{{ stageData[item.checkpos] }}</div>
            </div>
            <div class="line">
              <div class="key">审核结果:</div>
              <div class="value" style="color: #13CC47;">{{ item.examination }}</div>
            </div>
          </div>
        </div>
      </van-list>

    <van-popup v-model="startTimePopup" round position="bottom">
      <van-datetime-picker
          type="date"
          title="选择开始时间"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onStartConfirm"
      />
    </van-popup>

    <van-popup v-model="endTimePopup" round position="bottom">
      <van-datetime-picker
          type="month-day"
          title="选择结束时间"
          :min-date="startTimeStack"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onEndConfirm"
      />
    </van-popup>


    <van-popup v-model="resultPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="resultList"
          title="审核状态"
          @cancel="resultPicker = false"
          @confirm="onResultConfirm"
      />
    </van-popup>

  </div>
</template>

<script>
import moment from "moment";
import Vue from 'vue';

export default {
  name: "ApprovedList",
  data() {
    return {
      schoolName: '',
      startTime: '',
      endTime: '',
      result: '',
      auditUser: '',
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
      },
      tableLoading:false,
      refreshing:false,
      finished:false,
      //加载数据
      tableData:[],

      startTimePopup: false,
      endTimePopup: false,
      maxDate: new Date(),
      startTimeStack: '',

      resultPicker: false,
      resultList: [{text: '全部', value: 1}, {text: '通过', value: 1}, {text: '驳回', value: 1}],
      stageData:['已下架','送审','公司送审','厂家审核','学校审核'],
    }
  },
  activated() {
    this.tableData = [];
    this.onLoad();
  },
  methods: {
    //分页
    getList(page, params) {

      this.tableLoading = true
      this.$http.get(this.baseProductUrl + "/h5_product/queryProductExaminationList",{params:Object.assign({
          current: page.currentPage,
          size: page.pageSize
        }, params)},{
        emulateJSON:true
      }).then(response => {
        this.tableData = this.tableData.concat(response.data.records)
        this.page.total = response.data.total
        if(this.tableData.length >= this.page.total){
          this.finished = true;
        }else{
          this.page.currentPage++
        }
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
        // this.finished = true;
      })
    },
    onLoad() {
      let date = new Date();
      let year = moment(date).format('yyyy');
      let form = {
        school:this.schoolName,
        startTime :year+'-'+this.startTime+' 00:00:00',
        endTime:year+'-'+this.endTime+' 23:59:59',
        auditUser:this.auditUser,
        examination:this.result,
        deptId:Vue.prototype.deptId
      }
      this.getList(this.page,form)
    },
    // 查询事件
    searchChange() {
      let date = new Date();
      let year = moment(date).format('yyyy');

      let form = {
        school:this.schoolName,
        startTime :year+'-'+this.startTime+' 00:00:00',
        endTime:year+'-'+this.endTime+' 23:59:59',
        auditUser:this.auditUser,
        result:this.result,
        deptId:Vue.prototype.deptId
      }
      this.page.currentPage = 1
      this.getList(this.page, form)
    },

    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },


    onResultConfirm(item) {
      // item => { text: '高中一年级', value: 1}
      this.result = item.text;
      this.resultPicker = false
    },

    onMonthConfirm(val) {
      // val => {text: 1月, value: 1}
      this.month = val.text;
      this.monthPopup = false
    },

    onStartConfirm(val) {
      this.startTimeStack = val;
      this.startTimePopup = false;
      this.endTimePopup = true
    },
    onEndConfirm(val) {
      let date = new Date(this.startTimeStack)
      this.startTime = moment(date).format('MM-DD')

      let endDate = new Date(val);
      this.endTime = moment(endDate).format('MM-DD')
      this.endTimePopup = false
    }
  },

  mounted() {
    let date = new Date();
    this.endTime = moment(date).format('MM-DD')
    date.setDate(1);
    this.startTime = moment(date).format('MM-DD')
    this.searchChange();
  }
}

</script>
<style lang="less" scoped>


.filter {
  padding: 5px 18px 0;

  .box {
    box-sizing: border-box;
    border-radius: 100px;
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 33px;
    margin: 5px 0;
    padding: 0 13px;

    input {
      height: 33px;
      border-radius: 1000px;
      border: none;
      font-size: 12px;
      margin-left: 12px;
      width: 80%;
    }

    .van-icon {
      font-weight: bold;
      margin-top: 2px;
    }

    div {
      font-size: 12px;

      &.placeholder {
        color: #999;
      }

      &.value {
        color: #212121;
      }
    }


    &.handle {
      display: flex;
      align-items: center;
      padding-right: 6px !important;
      position: relative;

      i {
        width: 8px;
        height: 1px;
        background-color: #212121;
        display: inline-block;
        margin: 0 5px;
        //transform: translateY(1px);
      }

      img {
        height: 5px;
        width: 8px;
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .van-button {
    height: 33px;
    font-size: 14px;
    margin: 5px 0;
  }
}

.item {
  margin: 10px 18px;
  background: #FFFFFF;
  border-radius: 8px;

  .goodsInfo {
    display: flex;
    align-items: flex-start;
    padding: 12px 14px 0;

    img {
      width: 64px;
      height: 54px;
      min-width: 64px;
      border-radius: 5px;
      margin-right: 29px;
    }

    .right {
      .name {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
      }

      .price {
        span {
          font-size: 12px;
          font-weight: 500;
          color: #D53A26;

          &:last-child {
            font-size: 14px;
            margin-left: 3px;
          }
        }
      }

      .tagList {
        .tag {
          display: inline-block;
          margin-right: 5px;
          border: 1px solid #D61619;
          border-radius: 2px;
          padding: 2px 6px;
          line-height: 12px;
          font-size: 12px;
          font-weight: 400;
          color: #D61619;
        }
      }
    }
  }

  .key {
    color: #212121;
    font-size: 12px;
    display: inline-block;
  }

  .value {
    color: #999;
    font-size: 12px;
    display: inline-block;
    margin-left: 5px;
  }

  .timeGroup {
    display: flex;
    align-items: center;
    padding: 12px 16px;

    .time {
      flex: 1;

    }
  }

  .info {
    padding: 12px 16px;

    .line {
      margin-bottom: 4px;
    }
  }
}
</style>
